import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { lazy } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import * as API from "./services/WordPressAPI";
import LoadingProvider from "./components/LoadingProvider";
import Loader from "./components/Loader";

function getAsyncView(name) {
  return lazy(() => import(`./views/${name}`));
}

const AsyncHomeView = getAsyncView("HomeView");
const AsyncAboutView = getAsyncView("AboutView");
const AsyncBlogView = getAsyncView("BlogView");
const AsyncPostView = getAsyncView("PostView");
const AsyncServicesView = getAsyncView("ServicesView");
const AsyncNotFoundView = getAsyncView("NotFoundView");
// const AsyncDefaultView = getAsyncView("DefaultView");
const AsyncContactsView = getAsyncView("ContactsView");
const AsyncCareersView = getAsyncView("CareersView");
const AsyncHealthView = getAsyncView("HealthView");
const AsyncHowItWorksView = getAsyncView("HowItWorksView");
const AsyncProductView = getAsyncView("ProductView");
const AsyncProductFit = getAsyncView("ProductFitView");
const AsyncFaqsView = getAsyncView("FaqsView");
const AsyncTermsView = getAsyncView("TermsView");

export default function App() {
  const [pages, setPages] = useState(null);

  useEffect(() => {
    (async function getPages() {
      try {
        await API.getPages().then(setPages);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <>
      <>
        <LoadingProvider>
          <Loader />

          <Header />

          <Suspense fallback={<h1>...</h1>}>
            <Routes>
              <Route path="/">
                {pages &&
                  pages.map(({ link, template }, index) => {
                    switch (template) {
                      case "front-page.php":
                        return (
                          <Route
                            path={link}
                            key={index}
                            element={<AsyncHomeView pageName={link} templateName={template} />}
                          />
                        );

                      case "page-about.php":
                        return (
                          <Route
                            path={link}
                            key={index}
                            element={<AsyncAboutView pageName={link} templateName={template} />}
                          />
                        );

                      case "page-service.php":
                        return (
                          <Route key={index}>
                            <Route
                              path={link}
                              element={<AsyncServicesView pageName={link} templateName={template} />}
                            />

                            <Route path={`${link}/:id`} element={<AsyncProductView />} />
                          </Route>
                        );

                      case "page-blog.php":
                        return (
                          <Route key={index}>
                            <Route path={link} element={<AsyncBlogView pageName={link} templateName={template} />} />

                            <Route
                              path={`${link}/posts`}
                              element={<AsyncBlogView pageName={link} templateName={template} />}
                            />

                            <Route path={`${link}/:id`} element={<AsyncPostView />} />
                          </Route>
                        );

                      case "page-contacts.php":
                        return (
                          <Route
                            path={link}
                            key={index}
                            element={<AsyncContactsView pageName={link} templateName={template} />}
                          />
                        );

                      case "page-careers.php":
                        return (
                          <Route
                            path={link}
                            key={index}
                            element={<AsyncCareersView pageName={link} templateName={template} />}
                          />
                        );

                      case "page-faq.php":
                        return (
                          <Route
                            path={link}
                            key={index}
                            element={<AsyncFaqsView pageName={link} templateName={template} />}
                          />
                        );

                      case "page-general-health.php":
                        return (
                          <Route
                            path={link}
                            key={index}
                            element={<AsyncHealthView pageName={link} templateName={template} />}
                          />
                        );

                      case "page-remedy-fit-protein.php":
                        return (
                          <Route
                            path={link}
                            key={index}
                            element={<AsyncProductFit pageName={link} templateName={template} />}
                          />
                        );

                      case "page-how-it-works.php":
                        return (
                          <Route
                            path={link}
                            key={index}
                            element={<AsyncHowItWorksView pageName={link} templateName={template} />}
                          />
                        );

                      case "page-privacy.php":
                        return (
                          <Route
                            path={link}
                            key={index}
                            element={<AsyncAboutView pageName={link} templateName={template} />}
                          />
                        );

                      case "":
                        return (
                          <Route key={index}>
                            <Route path={link} element={<AsyncTermsView pageName={link} templateName={"page.php"} />} />
                          </Route>
                        );

                      default:
                        return <Route path="*" key={index} element={<AsyncNotFoundView />} />;
                    }
                  })}
              </Route>
            </Routes>
          </Suspense>

          <Footer />
        </LoadingProvider>
      </>
    </>
  );
}
