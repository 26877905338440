import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import legitscript from "../../assets/img/footer-legitscript.png";
import hipaa from "../../assets/img/footer-hipaa.png";
import stripe from "../../assets/img/footer-stripe.png";
import * as API from "../../services/WordPressAPI";
import { v4 as uuidv4 } from "uuid";

const Footer = () => {
  const [footerMenus, setFooterMenu] = useState(null);

  useEffect(() => {
    async function fetchData() {
      await API.getFooterMenu()
        .then((footerMenu) => footerMenu)
        .then(setFooterMenu);
    }
    fetchData();
  }, []);

  return (
    <>
      {footerMenus && (
              <footer className="c-footer">
              <div className="c-footer-wrap">
                <div className="c-footer-top">
                  <div className="c-footer-top-wrap">
                    <div className="c-footer-top-left">
                      <ul className="c-footer-top-menu--list">
                        {footerMenus[0].items.map(({ title, slug }) => {
                          return (
                            <li className="c-footer-top-menu--item" key={uuidv4()}>
                              <NavLink to={slug}>{title}</NavLink>
                            </li>
                          );
                        })}
                      </ul>
      
                      <ul className="social">
                        <li className="social--item">
                          <a href="/">
                            <svg
                              width="100%"
                              height="100%"
                              viewBox="0 0 38 38"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M22.6367 19C22.6367 21.0085 21.0085 22.6367 19 22.6367C16.9915 22.6367 15.3633 21.0085 15.3633 19C15.3633 16.9915 16.9915 15.3633 19 15.3633C21.0085 15.3633 22.6367 16.9915 22.6367 19Z"
                                fill="#8F8F8F"
                              />
                              <path
                                d="M27.5049 12.5655C27.3301 12.0918 27.0512 11.663 26.6888 11.311C26.3369 10.9486 25.9084 10.6697 25.4344 10.4949C25.0499 10.3456 24.4724 10.1679 23.4087 10.1194C22.258 10.067 21.913 10.0557 18.9999 10.0557C16.0866 10.0557 15.7416 10.0667 14.5912 10.1192C13.5275 10.1679 12.9497 10.3456 12.5655 10.4949C12.0915 10.6697 11.6627 10.9486 11.3111 11.311C10.9487 11.663 10.6698 12.0915 10.4946 12.5655C10.3453 12.9499 10.1676 13.5277 10.1192 14.5914C10.0667 15.7418 10.0554 16.0868 10.0554 19.0002C10.0554 21.9133 10.0667 22.2583 10.1192 23.409C10.1676 24.4727 10.3453 25.0502 10.4946 25.4346C10.6698 25.9086 10.9484 26.3371 11.3108 26.6891C11.6627 27.0515 12.0912 27.3304 12.5652 27.5052C12.9497 27.6548 13.5275 27.8325 14.5912 27.8809C15.7416 27.9334 16.0863 27.9444 18.9996 27.9444C21.9133 27.9444 22.2583 27.9334 23.4084 27.8809C24.4721 27.8325 25.0499 27.6548 25.4344 27.5052C26.3859 27.1382 27.1379 26.3861 27.5049 25.4346C27.6543 25.0502 27.832 24.4727 27.8807 23.409C27.9332 22.2583 27.9442 21.9133 27.9442 19.0002C27.9442 16.0868 27.9332 15.7418 27.8807 14.5914C27.8323 13.5277 27.6545 12.9499 27.5049 12.5655ZM18.9999 24.6022C15.9057 24.6022 13.3973 22.0942 13.3973 18.9999C13.3973 15.9056 15.9057 13.3975 18.9999 13.3975C22.0939 13.3975 24.6023 15.9056 24.6023 18.9999C24.6023 22.0942 22.0939 24.6022 18.9999 24.6022ZM24.8238 14.4853C24.1007 14.4853 23.5145 13.8991 23.5145 13.176C23.5145 12.453 24.1007 11.8668 24.8238 11.8668C25.5468 11.8668 26.1331 12.453 26.1331 13.176C26.1328 13.8991 25.5468 14.4853 24.8238 14.4853Z"
                                fill="#8F8F8F"
                              />
                              <path
                                d="M19 0C8.50819 0 0 8.50819 0 19C0 29.4918 8.50819 38 19 38C29.4918 38 38 29.4918 38 19C38 8.50819 29.4918 0 19 0ZM29.8443 23.4981C29.7916 24.6595 29.6069 25.4524 29.3373 26.1465C28.7705 27.612 27.612 28.7705 26.1465 29.3373C25.4527 29.6069 24.6595 29.7913 23.4984 29.8443C22.3349 29.8974 21.9632 29.9102 19.0003 29.9102C16.037 29.9102 15.6657 29.8974 14.5019 29.8443C13.3408 29.7913 12.5476 29.6069 11.8538 29.3373C11.1256 29.0633 10.4663 28.6339 9.92125 28.0788C9.36635 27.534 8.93698 26.8744 8.66301 26.1465C8.39339 25.4527 8.20871 24.6595 8.15594 23.4984C8.10231 22.3346 8.08984 21.963 8.08984 19C8.08984 16.037 8.10231 15.6654 8.15565 14.5019C8.20842 13.3405 8.39281 12.5476 8.66243 11.8535C8.9364 11.1256 9.36606 10.466 9.92125 9.92125C10.466 9.36606 11.1256 8.93669 11.8535 8.66272C12.5476 8.3931 13.3405 8.20871 14.5019 8.15565C15.6654 8.1026 16.037 8.08984 19 8.08984C21.963 8.08984 22.3346 8.1026 23.4981 8.15594C24.6595 8.20871 25.4524 8.3931 26.1465 8.66243C26.8744 8.9364 27.534 9.36606 28.079 9.92125C28.6339 10.4663 29.0636 11.1256 29.3373 11.8535C29.6072 12.5476 29.7916 13.3405 29.8446 14.5019C29.8977 15.6654 29.9102 16.037 29.9102 19C29.9102 21.963 29.8977 22.3346 29.8443 23.4981Z"
                                fill="#8F8F8F"
                              />
                            </svg>
                          </a>
                        </li>
                        <li className="social--item">
                          <a href="/">
                            <svg
                              width="100%"
                              height="100%"
                              viewBox="0 0 38 38"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M38 19C38 8.50547 29.4945 0 19 0C8.50547 0 0 8.50547 0 19C0 29.4945 8.50547 38 19 38C19.1113 38 19.2227 38 19.334 37.9926V23.2082H15.252V18.4508H19.334V14.9477C19.334 10.8879 21.8129 8.67617 25.4348 8.67617C27.1715 8.67617 28.6633 8.80234 29.0938 8.86172V13.107H26.6C24.6332 13.107 24.2473 14.0422 24.2473 15.4152V18.4434H28.9602L28.3441 23.2008H24.2473V37.2652C32.1887 34.9867 38 27.6762 38 19Z"
                                fill="#8F8F8F"
                              />
                            </svg>
                          </a>
                        </li>
                        <li className="social--item">
                          <a href="/">
                            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M19 0C8.50819 0 0 8.50819 0 19C0 29.4918 8.50819 38 19 38C29.4918 38 38 29.4918 38 19C38 8.50819 29.4918 0 19 0ZM27.6752 14.8142C27.6836 15.0012 27.6877 15.189 27.6877 15.3778C27.6877 21.1405 23.3012 27.7856 15.2795 27.7859H15.2798H15.2795C12.8167 27.7859 10.5249 27.064 8.59488 25.827C8.93611 25.8673 9.28343 25.8873 9.6351 25.8873C11.6784 25.8873 13.5588 25.1903 15.0516 24.0205C13.1425 23.9851 11.5329 22.7243 10.9774 20.9914C11.2433 21.0425 11.5167 21.0703 11.797 21.0703C12.1951 21.0703 12.5807 21.0167 12.9471 20.9166C10.9516 20.5171 9.44839 18.7536 9.44839 16.6418C9.44839 16.6221 9.44839 16.6044 9.44897 16.5862C10.0366 16.9129 10.7087 17.1095 11.4242 17.1315C10.2532 16.3502 9.48347 15.0145 9.48347 13.5014C9.48347 12.7024 9.69946 11.9539 10.074 11.3094C12.2246 13.9482 15.4387 15.6836 19.0632 15.866C18.9884 15.5465 18.9498 15.2137 18.9498 14.8716C18.9498 12.4641 20.903 10.5109 23.3114 10.5109C24.5658 10.5109 25.6988 11.0412 26.4946 11.8889C27.4882 11.6929 28.4211 11.33 29.2639 10.8304C28.9378 11.8483 28.2466 12.7024 27.3461 13.2425C28.2283 13.137 29.0691 12.903 29.8504 12.5557C29.2668 13.4304 28.5267 14.1987 27.6752 14.8142Z"
                                fill="#8F8F8F"
                              />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                    {footerMenus &&
                      footerMenus.map(({ items }, index) => {
                        return (
                          items && (
                            <div className="c-footer-top-menu" data-menu={index} key={index}>
                              <ul className="c-footer-top-menu--list">
                                {items.map(({ title, slug }) => {
                                  return (
                                    <li className="c-footer-top-menu--item" key={title}>
                                      <NavLink to={slug}>{title}</NavLink>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )
                        );
                      })}
                    <div className="c-footer-top-partners">
                      <ul className="c-footer-top-partners--list">
                        <li className="c-footer-top-partners--item">
                          <a href="/">
                            <img src={legitscript} alt="" loading="lazy"/>
                          </a>
                        </li>
                        <li className="c-footer-top-partners--item">
                          <a href="/">
                            <img src={hipaa} alt="" loading="lazy"/>
                          </a>
                        </li>
                        <li className="c-footer-top-partners--item">
                          <a href="/">
                            <img src={stripe} alt="" loading="lazy"/>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
      
                <div className="c-footer-bottom">
                  <div className="c-footer-bottom-wrap">
                    <span className="publish">© 2022 Remedy Well Integrative & Holistic Health. All rights reserved.</span>
                    <span className="development">
                      {/* <span className="development--text">Design & Development:</span> */}
      
                      <a
                        className="development-link"
                        target={"_blank"}
                        rel="noopener noreferrer"
                        href="https://litnevski.studio/"
                      >
                        <svg width="100%" height="100%" viewBox="0 0 33 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M26.6571 10.4445C24.3797 11.6397 21.9903 12.1468 19.5635 12.8712C17.6594 13.1972 15.7553 13.2334 14.8966 13.2334C14.6353 13.2334 10.2298 13.1248 10.2298 13.1248C7.76566 12.9437 6.3096 12.0382 4.06951 10.7704C4.21884 10.698 4.25618 10.5531 4.25618 10.4445H0C0.0746698 11.0964 0.410684 11.5311 1.23205 12.0744C3.92017 14.2838 7.01896 15.4067 10.3791 15.8051C12.3579 16.0224 14.3739 16.1673 16.39 16.0949C17.8088 16.0586 19.2275 15.9138 20.6089 15.6964C22.8116 15.3342 24.9771 14.7185 27.0305 13.8854C27.5905 13.6681 28.1879 13.4508 28.7479 13.2334C28.8972 13.161 30.5026 12.7264 31.1373 12.0019C31.436 11.7122 32.4067 11.2413 32.4067 10.4445H26.6571V10.4445Z"
                            fill="#8F8F8F"
                          />
                          <path
                            d="M25.4253 2.36737C25.4627 2.25871 25.5 2.15005 25.5 2.00517C25.5 1.7154 25.388 1.38942 25.164 1.17209C24.7906 0.84611 23.9319 0.701227 23.5212 1.20832C22.8492 2.04139 22.2145 2.87446 21.6545 3.74375C21.3185 4.25084 21.0198 4.83037 20.9825 5.44612C20.9451 6.38786 21.8412 6.96738 22.7372 6.75006C23.2599 6.60518 23.7453 6.20675 24.1186 5.84455C24.7533 5.26502 25.276 4.61305 25.7987 3.92486C26.1347 3.41777 25.9107 2.69336 25.4253 2.36737ZM23.3346 5.55478C23.2972 5.69966 23.3346 5.77211 23.3346 5.55478Z"
                            fill="#8F8F8F"
                          />
                          <path
                            d="M16.0543 0.375242C16.0169 0.411463 16.0169 0.447683 15.9796 0.483904C15.4569 0.157919 14.7102 0.23036 14.2622 0.809889C13.6275 1.7154 12.9928 2.65714 12.3954 3.59887C11.6861 4.79415 13.2915 5.84455 14.1875 5.04769C14.4115 5.15636 14.6729 5.15636 14.8969 5.12014C15.4196 5.44612 16.3156 5.33746 16.689 4.75793C17.1743 4.03352 17.5103 3.20045 17.6223 2.36737C17.6223 2.33115 17.6223 2.25871 17.6223 2.22249C17.6597 2.18627 17.697 2.15005 17.7343 2.07761C17.809 1.96895 17.8837 1.89651 17.921 1.78784C18.6677 0.701227 16.8756 -0.675154 16.0543 0.375242Z"
                            fill="#8F8F8F"
                          />
                          <path
                            d="M7.28056 2.7658C7.5419 2.18627 7.01921 1.49808 6.45919 1.3532C5.9365 1.20832 5.48848 1.46186 5.1898 1.82406C4.92846 1.75162 4.62978 1.7154 4.29376 1.78784C4.10709 1.82406 3.92041 1.93273 3.73374 2.07761C2.91237 1.82406 1.82966 2.43981 2.091 3.45399C2.42702 4.75793 2.98704 5.98943 3.77107 7.07605C4.36843 7.8729 5.78716 7.76424 5.97383 6.78628C6.57119 6.93116 7.35523 6.60518 7.50457 5.98943C7.61657 5.37368 7.57924 4.79415 7.4299 4.1784C7.28056 3.70753 7.05655 3.27289 7.28056 2.7658Z"
                            fill="#8F8F8F"
                          />
                          <path
                            d="M30.8389 4.3595C30.0922 4.21462 29.2335 4.61305 29.0095 5.4099C28.9721 5.591 28.8975 5.77211 28.8228 5.91699C28.9348 5.66344 28.6735 6.13431 28.6735 6.17053C28.6361 6.20675 28.3374 6.6414 28.4868 6.42408C28.3748 6.56896 28.2254 6.71384 28.0761 6.8225C27.5161 7.36581 27.4787 8.34377 28.0761 8.88707C28.6735 9.43038 29.5695 9.4666 30.2042 8.88707C30.9882 8.12644 31.5856 7.18471 31.8843 6.13431C32.0709 5.4099 31.6229 4.54061 30.8389 4.3595Z"
                            fill="#8F8F8F"
                          />
                          <path
                            d="M4.25618 10.5169C4.25618 10.2996 4.18151 10.0823 4.36819 10.0098C5.74958 9.57517 7.1683 9.53895 8.5497 9.1043C8.77371 9.03186 10.4538 8.88698 11.0511 8.81453C12.2085 8.66965 16.3527 8.77831 16.6514 8.77831C17.9954 8.81454 21.0569 8.88698 21.9156 9.03186C22.7743 9.17674 23.633 9.32162 24.4917 9.50273C25.2011 9.64761 25.9104 9.75627 26.5078 10.2634C26.5825 10.3358 26.6571 10.4082 26.8065 10.5169H32.4814C32.4814 10.4807 32.4814 10.4445 32.4814 10.4082C32.3694 9.61139 31.6227 8.52477 30.4653 7.94524C28.1879 6.85862 22.513 5.12004 21.9529 5.08382C20.1609 4.90271 18.2941 4.75783 16.502 4.72161C14.1873 4.64917 13.1046 4.64917 10.7898 4.64917C9.10972 4.68539 6.42161 5.15626 4.8162 5.73579C3.28547 6.2791 1.4934 7.03973 0.821368 8.56099C0.746698 8.74209 0.597359 8.88698 0.485354 9.06808C0.298679 9.39406 0 9.68383 0 10.046C0 10.2271 0 10.372 0 10.5531H4.25618V10.5169Z"
                            fill="#8F8F8F"
                          />
                          <path
                            d="M19.75 13.161C19.8247 13.0886 20.2354 11.8208 20.422 11.2051C20.534 10.8429 20.6087 10.4445 20.6834 10.046C20.7207 9.90115 20.9447 9.2854 21.0194 9.14052C21.0941 8.99564 21.0941 9.03186 21.3927 8.99563C22.5875 8.95941 15.9418 7.8728 13.8511 7.94524C11.1256 8.09012 9.40822 8.0539 9.10954 8.81453C9.07221 8.92319 9.07221 9.03186 9.10954 9.1043C9.14688 9.17674 9.14688 9.32162 9.18421 9.39406C9.40822 10.7342 9.78157 12.0382 10.5283 13.1972C10.5283 13.1972 10.5283 13.1972 10.5283 13.2334C11.723 14.8996 13.4031 16.0949 14.8965 16.1311C17.1739 16.2035 18.7046 14.9358 19.75 13.161ZM10.9763 11.1326C10.7896 11.0602 10.4536 10.0098 10.3789 9.35784C10.4536 9.2854 10.6403 9.97359 10.9763 11.1326ZM11.499 13.2334C9.93091 11.7484 9.74424 9.14052 9.7069 9.1043C9.81891 9.14052 9.96825 9.35784 9.96825 9.39406C10.1176 9.82871 10.2669 10.5531 10.4536 11.35C10.7896 12.4366 11.6483 13.2334 11.499 13.2334ZM10.9763 8.88697C11.051 8.81453 11.163 10.0823 11.8723 11.7484C11.5363 11.7122 10.9016 9.90115 10.9763 8.88697ZM15.9418 9.32162C15.7178 10.1547 15.3818 12.3641 14.9711 12.2917C14.5604 12.2193 14.1498 10.2996 14.0751 9.64761C14.0004 8.63343 14.1124 8.88697 14.7845 8.88697C15.0831 8.88697 15.3071 8.88697 15.6432 8.88697C15.8298 8.92319 16.0165 9.06808 15.9418 9.32162Z"
                            fill="#8F8F8F"
                          />
                        </svg>
                        Litnevski studio
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </footer>
      )}
    </>
  );
};

export default Footer;
