import React, { useState } from 'react'
import LoadingContext from '../../context/loading.context'

const LoadingProvider = ({ children }) => {
  const showLoading = () => {
    window.scrollTo(0, 0);

    toggleLoading(prevState => {
      return {
        ...prevState,
        loading: true
      }
    })
  }

  const hideLoading = () => {
    toggleLoading(prevState => {
      return {
        ...prevState,
        loading: false
      }
    })
  }

  const loadingState = {
    loading: true,
    showLoading,
    hideLoading
  }

  const [loading, toggleLoading] = useState(loadingState)

  return (
    <LoadingContext.Provider value={loading}>
      {children}
    </LoadingContext.Provider>
  )
}

export default LoadingProvider