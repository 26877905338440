import axios from "axios";
axios.defaults.baseURL = `https://remedy-well.litnevski.studio/wordpress/wp-json/remedywell/v1/`;
const CF7_TOKEN = "cmV3ZWFkbWluOkE4QHJCUTk1IzVmdCMzWm02OA==";

export async function getPages() {
  try {
    const response = await axios.get(`links`);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getHeaderMenu() {
  let menus = {};

  return axios
    .all([
      await axios.get(`menus/2`, {
        menu1: "myValue",
      }),
      await axios.get(`menus/14`, {
        menu1: "myValue",
      }),
      await axios.get(`menus/15`, {
        menu2: "myValue",
      })
    ])
    .then(
      axios.spread((...args) => {
        menus.nav = args[0].data;
        menus.modalTop = args[1].data;
        menus.modalBottom = args[2].data;
        return menus;
      })
    )
    .catch((error) => {
      console.log(error);
    });
}

export async function getFooterMenu() {
  let menus = [];

  return axios
    .all([
      await axios.get(`menus/16`, {
        menu1: "myValue",
      }),
      await axios.get(`menus/17`, {
        menu2: "myValue",
      }),
      await axios.get(`menus/18`, {
        menu2: "myValue",
      }),
      await axios.get(`menus/19`, {
        menu2: "myValue",
      }),
    ])
    .then(
      axios.spread((...args) => {
        menus.push(args[0].data, args[1].data, args[2].data, args[3].data);
        return menus;
      })
    )
    .catch((error) => {
      console.log(error);
    });
}

export async function getPage(page) {
  try {
    const response = await axios.get(`pagesbytemplate/${page}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getAboutPage() {
  try {
    const response = await axios.get(`pagesbytemplate/page-about.php`);
    return response.data[0];
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getBlogPosts(page) {
  try {
    const response = await axios.get(`posts?page=${page}`);
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response.status === 404) {
      return null;
    }
  }
}

export async function getBlogPostById(id) {
  try {
    const response = await axios.get(`posts/${id}`);
    return response.data[0];
  } catch (error) {
    if (error.response.status === 404) {
      return null;
    }
  }
}

export async function getBlogPostByCategory(id) {
  try {
    const response = await axios.get(`posts?category=${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function onSubmitCF7(data, formID) {
  try {
    let formdata = new FormData();
    formdata.append("your-name", data.user_name);
    formdata.append("your-email", data.user_email);
    formdata.append("your-message", data.user_message);

    const result = await axios({
      url: `https://remedy-well.litnevski.studio/wordpress/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback`,
      headers: {
        Authorization: `Basic ${CF7_TOKEN}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: formdata,
      method: "POST",
    });
    console.log(result.data.message);
    return result.data.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getProductById(id) {
  try {
    const response = await axios.get(`product/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}
