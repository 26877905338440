import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../../assets/icons/logo.png";
import { CSSTransition } from "react-transition-group";
import * as API from "../../services/WordPressAPI";
import { v4 as uuidv4 } from "uuid";

const Header = () => {
  const [menus, setMenus] = useState({});
  const [menu, setMenu] = useState(null);

  useEffect(() => {
    API.getHeaderMenu().then(setMenus);
  }, []);

  const toggleMenu = () => {
    setMenu(!menu);
    document.body.classList.toggle("modal-open");
  };

  const { nav, modalTop, modalBottom } = menus;

  return (
    <>
      {menus && (
        <header className="c-header">
          <div className="c-header-wrap">
            <Link className="c-header-logo" to="/">
              <img src={logo} alt="site logo" loading="lazy"/>
            </Link>

            <div className="c-header-right">
              <nav className="c-header-menu">
                {nav && (
                  <ul className="c-header-menu-ul">
                    {nav.items.map(({ slug, target, title }, index) => {
                      return (
                        <li key={index}>
                          <NavLink target={target} to={slug}>
                            {title}
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </nav>
              <div className="c-header-menu-btns" onClick={() => toggleMenu()}>
                <button className="btn btn-arrow-up btn-menu btn-menu--js">
                  <span className="btn-wrap">
                    <span className="btn-text">What we treat</span>
                    <svg width="100%" height="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.75 3L12.75 9L6.75 15"
                        stroke="none"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </button>
                <button className="btn-mobile btn-menu--js">
                  <svg width="100%" height="100%" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line
                      x1="1"
                      y1="4"
                      x2="31"
                      y2="4"
                      stroke="#000403"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <line
                      x1="1"
                      y1="12"
                      x2="31"
                      y2="12"
                      stroke="#000403"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <line
                      x1="1"
                      y1="20"
                      x2="31"
                      y2="20"
                      stroke="#000403"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <CSSTransition in={menu} classNames="c-header-modal" timeout={300} unmountOnExit>
            <div className="c-header-modal">
              <span className="c-header-mobile-bg c-header-modal--bg" onClick={() => toggleMenu()}></span>

              <div className="c-header-modal-box">
                <button className="btn btn-close" onClick={() => toggleMenu()}>
                  <svg width="100%" height="100%" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="50" height="50" rx="25" stroke="#000403" strokeWidth="2" />
                    <path
                      d="M26 26.001L18.4575 18.4585M18.4575 33.5434L26 26.001L18.4575 33.5434ZM26 26.001L33.5425 18.4585L26 26.001ZM26 26.001L33.5425 33.5434L26 26.001Z"
                      stroke="#000403"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="c-header-modal-wrap">
                  <div className="c-header-modal-top">
                    <div className="block-menu">
                      {modalTop && (
                        <ul>
                          {modalTop.items.map(({ title, slug }) => {
                            return (
                              <li key={uuidv4()}>
                                <NavLink to={slug} onClick={() => toggleMenu()}>
                                  {title}
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="c-header-modal-middle">
                    <div className="block-menu">
                      {modalBottom && (
                        <ul>
                          {modalBottom.items.map(({ title, slug }) => {
                            return (
                              <li key={uuidv4()}>
                                <NavLink to={slug} onClick={() => toggleMenu()}>
                                  {title}
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="c-header-modal-bottom">
                    <div className="block-btns">
                      <Link to="/" className="btn btn-grad-cover">
                        <span className="btn-wrap">
                          <span className="btn-text">Start a free consultation</span>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
        </header>
      )}
    </>
  );
};

export default Header;
