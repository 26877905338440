// import React from "react";
// import PulseLoader from "react-spinners/PulseLoader";

// const Loader = ({loading}) => {
//   return (
//           <PulseLoader className="c-spinner" color={"#000000"} loading={loading} size={30} speedMultiplier={1} />
//       );
// };

// export default Loader;

import React, { useContext } from "react";
import LoadingContext from "../../context/loading.context";
import PulseLoader from "react-spinners/PulseLoader";

const Spinner = () => {
  const { loading } = useContext(LoadingContext);

  return (
    <>
      <PulseLoader loading={loading} className="c-spinner" color={"#000000"} size={30} speedMultiplier={1} />
    </>
  );
};

export default Spinner;
